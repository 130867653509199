<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        v-else>
        <v-icon>{{addIcon.icon}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-autocomplete
          v-model="type"
          :items="[
            { value: 'Government Loan', text: 'Government Loan Deductions'},
            { value: 'AR-E', text: 'A/R-E'},
            { value: 'Others', text: 'Other Deductions'},
          ]"
          outlined
          dense
          clearable
          label="Type"
          class="mb-3"
          :error-messages="formErrorFields.typeErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('type', formErrorFields)"
        ></v-autocomplete>

        <v-text-field
          v-model="code"
          label="Code"
          outlined
          dense
          placeholder="Code"
          :class="formErrorFields.codeErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.codeErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('code', formErrorFields)"
        ></v-text-field>

        <v-text-field
          v-model="title"
          label="Title"
          outlined
          dense
          placeholder="Title"
          :class="formErrorFields.titleErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.titleErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('title', formErrorFields)"
        ></v-text-field>

        <v-row>
          <v-col cols="12" md="6" lg="6" class="pb-0">
            <v-text-field
              v-model="oracleCode"
              label="Oracle Code"
              outlined
              dense
              placeholder="Oracle Code"
              :class="formErrorFields.oracle_codeErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.oracle_codeErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('oracle_code', formErrorFields)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="pb-0">
            <v-text-field
              v-model="fsAccount"
              label="FS Account"
              outlined
              dense
              placeholder="FS Account"
              :class="formErrorFields.fs_accountErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.fs_accountErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('fs_account', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" :md="formState==='Edit' ? 6 : 12" :lg="formState==='Edit' ? 6 : 12" class="pt-0">
            <v-autocomplete
              v-model="carryOver"
              :items="[{ text: 'Allow', value: 'Y' }, { text:'Do not allow', value: 'N' }]"
              outlined
              dense
              clearable
              label="Carry Over"
              class="mb-3"
              :error-messages="formErrorFields.carry_overErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('carry_over', formErrorFields)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="6" v-if="formState==='Edit'" class="pt-0">
            <v-text-field
              v-model="priorityLevel"
              label="Priority Level"
              outlined
              dense
              placeholder="Priority Level"
              :class="formErrorFields.priority_levelErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.priority_levelErrorMessage"
              :disabled="formState===null"
              @keydown="formError().remove('priority_level', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const loading = ref(false)
    const message = ref('')
    const addIcon = { icon: mdiPlusOutline, name: 'mdiPlusOutline' }
    const type = ref('')
    const code = ref('')
    const title = ref('')
    const fsAccount = ref('')
    const oracleCode = ref('')
    const carryOver = ref('')
    const priorityLevel = ref('')
    const formState = ref(null)
    const formErrorFields = ref({
      codeErrorMessage: '',
      titleErrorMessage: '',
      typeErrorMessage: '',
      fs_accountErrorMessage: '',
      oracle_codeErrorMessage: '',
      carry_overErrorMessage: '',
      priority_levelErrorMessage: '',
    })

    watch(() => props.data, () => {
      type.value = props.data.type
      code.value = props.data.code
      title.value = props.data.title
      fsAccount.value = props.data.fs_account
      oracleCode.value = props.data.oracle_code
      carryOver.value = props.data.carry_over
      priorityLevel.value = props.data.priority_level
      formState.value = 'Edit'
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      const formData = {
        code: code.value,
        title: title.value,
        type: type.value,
        fsAccount: fsAccount.value,
        oracleCode: oracleCode.value,
        carryOver: carryOver.value,
        priorityLevel: priorityLevel.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/deductions`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/deductions/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      emit('updateUniqueID')
    }

    const reset = () => {
      formState.value = null
      code.value = ''
      title.value = ''
      type.value = ''
      fsAccount.value = ''
      oracleCode.value = ''
      carryOver.value = ''
      priorityLevel.value = ''
    }

    return {
      code,
      title,
      type,
      fsAccount,
      oracleCode,
      carryOver,
      priorityLevel,
      addIcon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
    }
  },

  components: {
    snackbar,
  },
}
</script>
